import { observer } from "mobx-react"
import SelectAnt from "../../../common/components/form/SelectAnt";
import { useEffect } from "react";
import { enterpriseStore } from "../../enterprise/EnterpriseStore";
import { configZNSStore } from "./ConfigZNSStore";
import { checkLength, sttPage } from "../../../common/utils/Utils";
import Loading from "../../../common/components/Loading";
import NoContent from "../../../common/components/NoContent";
import Paginate from "../../../common/components/Paginate";
import InputSearch from "../../../common/components/form/InputSearch";
import ConfigZNSModal from "./components/ConfigZNSModal";

const ConfigZNS = () => {
    const handleChangePage = async (data: any) => {
        configZNSStore.page = data.selected;
        await configZNSStore.getConfigZnsList();
    }

    useEffect(() => {
        configZNSStore.getConfigZnsList().then();

        enterpriseStore.page = 0;
        enterpriseStore.getEnterprise().then();

        return () => {
            enterpriseStore.keyword = "";
            enterpriseStore.page = 0;
        }
    }, [])

    return (
        <div className="enterprise_page">
            <div className='d-flex align-items-center justify-content-between mb-6'>
                <h1 className='d-flex color-005CF7 fw-bolder fs-3 align-items-center my-1'>Thiết lập đơn giá ZNS</h1>
            </div>
            <div className="d-flex mb-4 align-items-center gap-12px">
                <InputSearch 
                    value={configZNSStore.keyword} 
                    callApi={() => {
                        configZNSStore.getConfigZnsList().then();
                    }} 
                    onChange={(e: any) => configZNSStore.keyword = e.currentTarget.value}
                />
            </div>
            <div id='kt_ecommerce_sales_table_wrapper' className='dataTables_wrapper dt-bootstrap4 no-footer'>
                {configZNSStore.isLoading 
                    ? <Loading/> 
                    : (
                        <div className='table-responsive'>
                            {checkLength(configZNSStore.templateZnsList)  ?
                                    <table className='table align-middle table-hover table-rounded table-striped border gy-7 gs-7'>
                                        <thead>
                                            <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                                <th>STT</th>
                                                <th>Thương hiệu</th>
                                                <th className="text-center">Tỷ lệ chênh lệch</th>
                                                <th className="text-center">Thao tác</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {configZNSStore.templateZnsList.map((item, i: number) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{sttPage(configZNSStore.page, i)}</td>
                                                    <td>{item?.name}</td>
                                                    <td className="text-center">{item?.extraPercent}</td>
                                                    <td className="text-center" width='10%'>
                                                        <button 
                                                            className="btn-gray-fill min-h-32px w-100 min-w-100px"
                                                            onClick={() => {
                                                                configZNSStore.isOpenConfigZNSModal = true;
                                                                configZNSStore.clearConfigForm();
                                                                configZNSStore.configZNSRequest.enterpriseIds = item.id;

                                                                enterpriseStore.listEnterprise.push({
                                                                    id: item.id,
                                                                    name: item.name,
                                                                })
                                                            }}
                                                        >
                                                            Thiết lập
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </table> : <NoContent/>
                            }
                            <Paginate 
                                callback={(e: any) => handleChangePage(e)} 
                                totalPage={configZNSStore.totalPage}
                                currentPage={configZNSStore.page}
                            />
                        </div>
                    )}
            </div>
            {configZNSStore.isOpenConfigZNSModal && <ConfigZNSModal />}
        </div>
    )
}

export default observer(ConfigZNS)