import {FC, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import TopBarProgress from 'react-topbar-progress-indicator'
import EnterprisePage from "../pages/enterprise/EnterprisePage";
import PackagePage from "../pages/package/PackagePage";
import ApprovedPackagePage from "../pages/approved_package/ApprovedPackagePage";
import ApproveDepositRequest from '../pages/managementWallet/approveDepositRequest/ApproveDepositRequest'
import ConfigZNS from '../pages/managementWallet/configZNS/ConfigZNS'
import ReportCompareZNS from '../pages/managementWallet/reportCompareZNS/ReportCompareZNS'

const PrivateRoutes = () => {

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/enterprise' />} />
        <Route path='/enterprise' element={<EnterprisePage/>} />
        <Route path='/setup-package' element={<PackagePage/>} />
        <Route path='/approved-package' element={<ApprovedPackagePage/>} />
        <Route path='/approved-deposit-request' element={<ApproveDepositRequest/>} />
        <Route path='/config-zns-price' element={<ConfigZNS/>} />
        <Route path='/report-compare-zns' element={<ReportCompareZNS />} />

        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspenseView = ({children}: any) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
