/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {AsideMenuItem} from './AsideMenuItem'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'

export function AsideMenuMain() {

    return (
        <>
            {/*<div className="menu-item mb-2">*/}
            {/*    <div className="menu-content">*/}
            {/*        <span className="menu-heading fw-bold text-uppercase fs-7">Trang chủ</span>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <AsideMenuItemWithSub
                to='/'
                title='Quản lý doanh nghiệp'
                fontIcon='bi-archive'
            >
                <AsideMenuItem
                    to='enterprise'
                    title='Doanh nghiệp'
                    hasBullet={true}
                />
            </AsideMenuItemWithSub>

            <AsideMenuItemWithSub
                to='/'
                title='Gói bán'
                fontIcon='bi-archive'
            >
                <AsideMenuItem
                    to='approved-package'
                    title='Yêu cầu nâng cấp'
                    hasBullet={true}
                />
                <AsideMenuItem
                    to='setup-package'
                    title='Thiết lập gói'
                    hasBullet={true}
                />
            </AsideMenuItemWithSub>

            <AsideMenuItemWithSub
                to='/'
                title='Ví tiền'
                fontIcon='bi-archive'
            >
                <AsideMenuItem
                    to='approved-deposit-request'
                    title='Yêu cầu nạp tiền'
                    hasBullet={true}
                />
                <AsideMenuItem
                    to='config-zns-price'
                    title='Thiết lập đơn giá ZNS'
                    hasBullet={true}
                />
                <AsideMenuItem
                    to='report-compare-zns'
                    title='Báo cáo đối soát ZNS'
                    hasBullet={true}
                />
            </AsideMenuItemWithSub>

            {/*<div className="menu-item mt-5 mb-2">*/}
            {/*    <div className="menu-content">*/}
            {/*        <span className="menu-heading fw-bold text-uppercase fs-7"></span>*/}
            {/*    </div>*/}
            {/*</div>*/}

        </>
    )
}
