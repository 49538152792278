import { Modal, Spin } from "antd";
import { observer } from "mobx-react"
import { configZNSStore } from "../ConfigZNSStore";
import InputAnt from "../../../../common/components/form/InputAnt";
import { enterpriseStore } from "../../../enterprise/EnterpriseStore";
import SelectAnt from "../../../../common/components/form/SelectAnt";
import { numberUtil } from "../../../../common/utils/NumberUtil";

const ConfigZNSModal = () => {
    return (
        <Modal
            title={<span className="color-005CF7">Thiết lập gói ZNS</span>}
            width={500}
            maskClosable={false}
            open={configZNSStore.isOpenConfigZNSModal}
            onCancel={() => configZNSStore.isOpenConfigZNSModal = false}
            footer={null}
        >
            <div className="d-flex flex-column gap-8px">
                <div>
                    <div>Thương hiệu <span className="text-danger">*</span></div>
                    <SelectAnt 
                        placeholder="Thương hiệu"
                        isFetching={enterpriseStore.isLoading}
                        isLoadingReadMore={false}
                        isCallback={true}
                        isError={configZNSStore.configZNSError.enterpriseIds}
                        value={configZNSStore.configZNSRequest.enterpriseIds} 
                        onChange={(e: any) => {
                            configZNSStore.configZNSRequest.enterpriseIds = e || null;
                        }} 
                        options={enterpriseStore.listEnterprise}
                        onSearch={(keyword: string) => {
                            enterpriseStore.keyword = keyword;
                            enterpriseStore.page = 0;
                            enterpriseStore.getEnterprise().then();
                        }}
                        callback={() => {
                            if (enterpriseStore.page < enterpriseStore.totalPage) {
                                enterpriseStore.getEnterpriseLoadMore().then();
                            }
                        }}
                    />
                </div>
                <div>
                    <div>Tỷ lệ chênh lệch <span className="text-danger">*</span></div>
                    <InputAnt
                        className="h-42px"
                        suffix="%"
                        isError={configZNSStore.configZNSError.extraPercent}
                        value={configZNSStore.configZNSRequest.extraPercent}
                        onChange={(e: any) => {
                            configZNSStore.configZNSRequest.extraPercent = Number(numberUtil.regexNumber(e));
                        }}
                        placeholder="Số tiền"
                    />
                </div>
            </div>
            <div className="d-flex justify-content-end mt-16px gap-12px">
                <button 
                    className="btn-primary-fill min-w-140px"
                    onClick={() => {
                        configZNSStore.configZNSTemplate();
                    }}
                >
                    {configZNSStore.isLoadingBtn ? <Spin size="small"></Spin> : "Xác nhận"}
                </button>
            </div>
        </Modal>
    )
}

export default observer(ConfigZNSModal)