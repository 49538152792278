import Constants from '../../../common/constants/Constants';
import {getRequest, IApiResponse, postRequest, putRequest} from '../../../common/helpers/RequestHelper'
import { configZNSStore } from './ConfigZNSStore';

class ConfigZNSService {
    public getConfigZnsList(): Promise<IApiResponse> {
        const {keyword, page} = configZNSStore

        return getRequest(`/iss_365_zalo/setting_zns_templates?page=${page}${keyword ? `&keyword=${keyword}` : ''}`);
    }

    public configZNSTemplate(requestData: any): Promise<IApiResponse> {
        return putRequest("/iss_365_zalo/setting_zns_templates/by_enterprise", requestData)
    }
}

export const configZNSService = new ConfigZNSService()

