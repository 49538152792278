import { observer } from "mobx-react"
import InputSearch from "../../../common/components/form/InputSearch"
import SelectAnt from "../../../common/components/form/SelectAnt"
import Paginate from "../../../common/components/Paginate"
import { reportCompareZNSStore } from "./ReportCompareZNSStore"
import Loading from "../../../common/components/Loading"
import { checkLength, sttPage } from "../../../common/utils/Utils"
import NoContent from "../../../common/components/NoContent"
import { useEffect, useMemo, useRef } from "react"
import { enterpriseStore } from "../../enterprise/EnterpriseStore"
import DateRangePickerAnt from "../../../common/components/form/DateRangePickerAnt"
import { dateUtils } from "../../../common/utils/DateUtils"
import dayjs from "dayjs"
import { numberUtil } from "../../../common/utils/NumberUtil"
import debounce from 'lodash/debounce'

const ReportCompareZNS = () => {
    const handleSearchEnterpriseList = (keyword: string) => {
        enterpriseStore.keyword = keyword;
        enterpriseStore.page = 0;
        enterpriseStore.getEnterprise().then();
    }

    const handleChangePage = async (data: any) => {
        reportCompareZNSStore.searchObj.page = data.selected;
        await reportCompareZNSStore.getReportCompareZNSData();
    }

    useEffect(() => {
        reportCompareZNSStore.getReportCompareZNSData().then();
        reportCompareZNSStore.getStatisticalZnsReport().then();

        enterpriseStore.page = 0;
        enterpriseStore.getEnterprise().then();

        return () => {
            enterpriseStore.keyword = "";
            enterpriseStore.page = 0;
            reportCompareZNSStore.clearFilter();
        }
    }, [])

    return (
        <div className="enterprise_page">
            <div className='d-flex align-items-center justify-content-between mb-6'>
                <h1 className='d-flex color-005CF7 fw-bolder fs-3 align-items-center my-1'>Báo cáo đối soát ZNS</h1>
            </div>
            <div className="d-flex gap-16px mb-16px">
                <div className="width-20 bg-ECF5FF rounded-6px pt-10px px-10px pb-2px">
                    <div className="fs-14px fw-500">Số lượng tin ZNS</div>
                    <div className="fw-bold fs-24px text-end">{reportCompareZNSStore.statisticalZnsData?.count} ZNS</div>
                </div>
                <div className="width-20 bg-ECF5FF rounded-6px pt-10px px-10px pb-2px">
                    <div className="fs-14px fw-500">Tổng chi tiêu</div>
                    <div className="fw-bold fs-24px text-end">{numberUtil.number_format(reportCompareZNSStore.statisticalZnsData?.total)}</div>
                </div>
                <div className="width-20 bg-ECF5FF rounded-6px pt-10px px-10px pb-2px">
                    <div className="fs-14px fw-500">Tổng tiền thực thu</div>
                    <div className="fw-bold fs-24px text-end">{numberUtil.number_format(reportCompareZNSStore.statisticalZnsData?.actualTotal)}</div>
                </div>
            </div>
            <div className="d-flex mb-4 align-items-center gap-12px">
                <SelectAnt 
                    width={220} 
                    isShowClear
                    placeholder="Thương hiệu"
                    isFetching={enterpriseStore.isLoading}
                    isLoadingReadMore={false}
                    isCallback={true}
                    value={reportCompareZNSStore.searchObj.enterpriseIds} 
                    onChange={(e: any) => {
                        if (!e) {
                            handleSearchEnterpriseList("")
                        }

                        reportCompareZNSStore.searchObj.enterpriseIds = e;
                        reportCompareZNSStore.searchObj.page = 0;
                        reportCompareZNSStore.getReportCompareZNSData().then();
                    }} 
                    options={enterpriseStore.listEnterprise}
                    onSearch={(keyword: string) => handleSearchEnterpriseList(keyword)}
                    callback={() => {
                        if (enterpriseStore.page < enterpriseStore.totalPage) {
                            enterpriseStore.getEnterpriseLoadMore().then();
                        }
                    }}
                />
                <DateRangePickerAnt
                    allowClear
                    className="h-42px min-h-42px"
                    placeholder={["Từ ngày", "Đến ngày"]}
                    convertDate={"DD/MM/YYYY"}
                    value={reportCompareZNSStore.searchObj?.fromDate ? [dayjs(reportCompareZNSStore.searchObj?.fromDate), dayjs(reportCompareZNSStore.searchObj?.toDate)]: []}
                    onChange={(dates: any) => {
                        reportCompareZNSStore.searchObj.fromDate = checkLength(dates) ? dateUtils.convertTimestamp(dates[0]) : "";
                        reportCompareZNSStore.searchObj.toDate = checkLength(dates) ? dateUtils.convertTimestamp(dates[1], true) : "";
                        reportCompareZNSStore.searchObj.page = 0;
                        reportCompareZNSStore.getReportCompareZNSData().then();
                    }}
                />
            </div>
            <div id='kt_ecommerce_sales_table_wrapper' className='dataTables_wrapper dt-bootstrap4 no-footer'>
                {reportCompareZNSStore.isLoading 
                    ? <Loading/> 
                    : (
                        <div className='table-responsive'>
                            {checkLength(reportCompareZNSStore.reportDataList)  ?
                                    <table className='table align-middle table-hover table-rounded table-striped border gy-7 gs-7'>
                                        <thead>
                                            <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                                <th>STT</th>
                                                <th>Thương hiệu</th>
                                                <th>Tên OA</th>
                                                <th>Mẫu tin ZNS</th>
                                                <th className="text-end">Đơn giá</th>
                                                <th className="text-center">ZNS đã gửi</th>
                                                <th className="text-end">Tổng tiền</th>
                                                <th className="text-end">Tổng tiền thực thu</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {reportCompareZNSStore.reportDataList.map((item, i: number) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{sttPage(reportCompareZNSStore.searchObj.page, i)}</td>
                                                    <td>{item?.name}</td>
                                                    <td>{item?.oaName}</td>
                                                    <td>{item?.template?.templateName}</td>
                                                    <td className="text-end">{numberUtil.number_format(item?.originalPrice)}</td>
                                                    <td className="text-center">{item?.count}</td>
                                                    <td className="text-end">{numberUtil.number_format(item?.total)}</td>
                                                    <td className="text-end">{numberUtil.number_format(item?.actualTotal)}</td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </table> : <NoContent/>
                            }
                            <Paginate 
                                callback={(e: any) => handleChangePage(e)} 
                                totalPage={reportCompareZNSStore.totalPage}
                                currentPage={reportCompareZNSStore.searchObj.page}
                            />
                        </div>
                    )}
            </div>
        </div>
    )
}

export default observer(ReportCompareZNS)
