import {getRequest, IApiResponse, putRequest} from '../../../common/helpers/RequestHelper'
import { approveDepositRequestStore } from './ApproveDepositRequestStore'

class ApproveDepositRequestService {
    public getApproveDepositRequest(): Promise<IApiResponse> {
        const {page, pageSize, keyword, status} = approveDepositRequestStore.searchObj;

        return getRequest(`/iss_365_user/transaction_history/all?page=${page}&size=${pageSize}` 
            + `${keyword ? `&keyword=${keyword}` : ''}`
            + `${status ? `&status=${status}` : ''}`
            + `&type=DEPOSIT&serviceType=DEPOSIT`
        )
    }

    public changeApproveDepositRequestStatus(requestData: {status: string, transactionId: number, enterpriseId: number, userId: number}): Promise<IApiResponse> {
        return putRequest(`/iss_365_user/wallets/deposit_process`, requestData);
    }
}

export const approveDepositRequestService = new ApproveDepositRequestService()

