import {makeAutoObservable} from 'mobx'
import { IReportData, ISearchObj, IStatisticalZns } from './ReportCompareZNSInterface';
import { reportCompareZNSService } from './ReportCompareZNSService';
import { HttpStatusCode } from 'axios';

const searchObjInit: ISearchObj = {
    page: 0,
    enterpriseIds: null,
    zaloOAIds: null,
    fromDate: "",
    toDate: "",
}

class ReportCompareZNSStore {
    isLoading: boolean = false;

    reportDataList: IReportData[] = [];
    statisticalZnsData: IStatisticalZns = {
        actualTotal: 0,
        count: 0,
        total: 0
    };
    totalPage: number = 0;
    searchObj: ISearchObj = searchObjInit;

    constructor() {
        makeAutoObservable(this)
    }

    clearFilter() {
        this.searchObj = searchObjInit;
    }

    async getReportCompareZNSData() {
        this.isLoading = true;
        const result = await reportCompareZNSService.getZnsReconciliations();
        this.isLoading = false;

        if (result.status === HttpStatusCode.Ok) {
            this.reportDataList = result.body.data;
            this.totalPage = result.body.metadata.totalPage;
        }
    }

    async getStatisticalZnsReport() {
        this.isLoading = true;
        const result = await reportCompareZNSService.getZnsReconciliationStatistical();
        this.isLoading = false;

        if (result.status === HttpStatusCode.Ok) {
            this.statisticalZnsData = result.body;
        }
    }
}

export const reportCompareZNSStore = new ReportCompareZNSStore();


