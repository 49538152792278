import {makeAutoObservable} from 'mobx'
import $ from 'jquery'
import {packageService} from "./PackageService";
import {HttpStatusCode} from "axios";
import {checkLength} from "../../common/utils/Utils";

interface IError{
    name: string,
    limitBrand: string,
    limitUser: string,
    limitClient: string,
}
interface IDataRequest{
    id: string | number,
    name: string,
    type: string,
    limitUser: string | number,
    limitClient: string | number,
    isActive: boolean,
    unlimitedUser: boolean,
    unlimitedClient: boolean,
    isRecommended: boolean,
    packagePrices: {
        price?: number | string,
        validityPeriod: number | string,
        timeUnit?: string,
        errorPrice?: boolean,
        errorValidityPeriod: boolean,
    }[],
}

export enum TypeTime {
    PAID = "PAID",
    TRIAL = "TRIAL"
}

export enum TypeDate {
    YEAR = "YEAR",
    DAY = "DAY"
}

class PackageStore {
    id: number | string = ''
    page: number = 0
    type: number | null = null
    name: string = ''
    isActive: boolean = false
    totalPage: number = 0
    listPackage: any[] = []
    errors: IError = {
        name: '',
        limitBrand: '',
        limitUser: '',
        limitClient: '',
    }
    dataRequest: IDataRequest = {
        id: '',
        name: '',
        type: TypeTime.PAID,
        limitUser: 0,
        limitClient: 0,
        isActive: true,
        unlimitedUser: false,
        unlimitedClient: false,
        isRecommended: false,
        packagePrices: [
            {
                price: 0,
                validityPeriod: 1,
                errorPrice: false,
                errorValidityPeriod: false,
            }
        ],
    }
    isLoading: boolean = false
    isLoadingBt: boolean = false

    constructor() {
        makeAutoObservable(this)
    }

    clearError(){
        this.errors = {
            name: '',
            limitBrand: '',
            limitUser: '',
            limitClient: '',
        }
    }

    clearForm(){
        this.clearError()
        this.dataRequest = {
            id: '',
            name: '',
            type: TypeTime.PAID,
            limitUser: '',
            limitClient: '',
            isActive: true,
            unlimitedUser: false,
            unlimitedClient: false,
            isRecommended: false,
            packagePrices: [
                {
                    price: '',
                    validityPeriod: 1,
                    errorPrice: false,
                    errorValidityPeriod: false,
                }
            ],
        }
    }

    async getPackage() {
        this.isLoading = true;
        const result = await packageService.getPackage()
        this.isLoading = false;
        if (result.status === 200) {
            this.listPackage = result.body.data;
            this.totalPage = result.body.metadata.totalPage
        }
    }

    async getDetail(id: any) {
        this.clearError()
        const result = await packageService.getDetail(id);
        if (result.status === 200) {
            this.dataRequest = result.body;
            if(!checkLength(this.dataRequest.packagePrices)){
                this.dataRequest.packagePrices = [
                    {
                        price: 0,
                        validityPeriod: this.dataRequest.type === TypeTime.PAID ? 1 : 7,
                        errorPrice: false,
                        errorValidityPeriod: false,
                    }
                ]
            }
        }
    }


    async changeState(id: number, isActive: boolean) {
        this.isLoadingBt = true
        const result = await packageService.changeState(id, !isActive)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.Ok) {
            toastr.success(`${isActive ? 'Ngưng' : 'Bật'} hoạt động thiết lập gói thành công`)
            this.listPackage.map((item: any) => {
                if(item.id == id){
                    item.isActive = !isActive
                }
            })
        } else toastr.error(result.body.message)

    }

    async changeRecommended(id: number) {
        this.isLoadingBt = true
        const result = await packageService.changeRecommended(id)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.Ok) {
            toastr.success(`Thay đổi gói khuyên dùng thành công`)
            this.listPackage.map((item: any) => {
                item.isRecommended = item.id === id
            })
        } else toastr.error(result.body.message)

    }

    async addEdit(){
        this.clearError()

        let {id, name, isActive, type, limitUser, limitClient, isRecommended, unlimitedUser, unlimitedClient, packagePrices} = this.dataRequest

        let error = false

        let arrPackagePrices: any = packagePrices && packagePrices.map((item: any) => {
            if(!item.validityPeriod){
                error = true
                item.errorValidityPeriod = 'Thời hạn dùng thử không được để trống!'
            }

            if(type === TypeTime.PAID){
                if(!item.price){
                    error = true
                    item.errorPrice = true
                }
                if(item.price && item.validityPeriod){
                    return {
                        price: item.price,
                        validityPeriod: item.validityPeriod,
                        timeUnit: TypeDate.YEAR
                    }
                }
            }else {
                if(item.validityPeriod){
                    return {
                        validityPeriod: item.validityPeriod,
                        timeUnit: TypeDate.DAY
                    }
                }
            }
        })

        if(!name?.trim()){
            error = true
            this.errors.name = 'Tên gói không được để trống!'
        }

        if(!limitUser && !unlimitedUser){
            error = true
            this.errors.limitUser = 'Số lượng người dùng không được để trống!'
        }
        if(!limitClient && !unlimitedClient){
            error = true
            this.errors.limitClient = 'Số lượng bản ghi không được để trống!'
        }

        if(error){
            return
        }

        let data = {
            name: name,
            type: type,
            isActive: type === TypeTime.PAID ? isActive : true,
            limitUser: limitUser,
            limitClient: limitClient,
            unlimitedUser: unlimitedUser,
            unlimitedClient: unlimitedClient,
            isRecommended: type === TypeTime.PAID ? isRecommended : false,
            packagePrices: arrPackagePrices,
        }

        this.isLoadingBt = true
        const result = id ? await packageService.edit(id, data) : await packageService.add(data)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.Ok) {
            toastr.success(`${id ? "Cập nhật" : 'Thiết lập'} thiết lập gói thành công`)
            $('#close_package').trigger('click')
            this.clearForm()
            this.getPackage().then()
        } else toastr.error(result.body.message)
    }


    async delete() {
        this.isLoadingBt = true
        const result = await packageService.delete(this.id)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.Ok) {
            toastr.success(`Xóa thiết lập gói thành công`)
            $('#close_delete').trigger('click')
            this.getPackage().then()
        } else toastr.error(result.body.message)

    }


}

export const packageStore = new PackageStore();


