import {makeAutoObservable} from 'mobx'
import { configZNSService } from './ConfigZNSService';
import { HttpStatusCode } from 'axios';
import { IConfigZNSError, IConfigZNSRequest, ITemplateZNS } from './ConfigZNSInterface';

const configZNSRequestInit: IConfigZNSRequest = {
    enterpriseIds: null,
    extraPercent: null,
}

const configZNSErrorInit: IConfigZNSError = {
    enterpriseIds: '',
    extraPercent: ''
}

class ConfigZNSStore {
    isLoading: boolean = false;
    isLoadingBtn: boolean = false;

    templateZnsList: ITemplateZNS[] = [];
    totalPage: number = 0;
    page: number = 0;
    keyword: string = '';

    isOpenConfigZNSModal: boolean = false;
    configZNSRequest: IConfigZNSRequest = configZNSRequestInit;
    configZNSError: IConfigZNSError = configZNSErrorInit;

    constructor() {
        makeAutoObservable(this)
    }

    async getConfigZnsList() {
        this.isLoading = true;
        const result = await configZNSService.getConfigZnsList();
        this.isLoading = false;

        if (result.status === HttpStatusCode.Ok) {
            this.templateZnsList = result.body.data;
            this.totalPage = result.body.metadata.totalPage;
        }
    }

    clearConfigForm() {
        this.configZNSRequest = configZNSRequestInit;
        this.configZNSError = configZNSErrorInit;
    }

    checkValidateConfigZNS() {
        let isValid = false;
        const {enterpriseIds, extraPercent} = this.configZNSRequest;

        if(!enterpriseIds) {
            isValid = true;
            this.configZNSError.enterpriseIds = "Thương hiệu không được để trống";
        }

        if(!extraPercent) {
            isValid = true;
            this.configZNSError.extraPercent = "Tỷ lệ chênh lệch không được để trống";
        }

        return isValid;
    }

    async configZNSTemplate() {
        if(this.checkValidateConfigZNS()) return;
        
        const {enterpriseIds, extraPercent} = this.configZNSRequest;

        const requestData = {
            enterpriseIds: [enterpriseIds],
            extraPercent: extraPercent,
        }

        this.isLoadingBtn = true;
        const result = await configZNSService.configZNSTemplate(requestData);
        this.isLoadingBtn = false;

        if (result.status === HttpStatusCode.Ok) {
            toastr.success(`Thiết lập đơn giá ZNS thành công`);
            this.templateZnsList = this.templateZnsList?.map((item) => item.id === enterpriseIds ? {...item, extraPercent: extraPercent || 0} : item);
            this.isOpenConfigZNSModal = false;
        } else toastr.error(result.body.message)
    }
}

export const configZNSStore = new ConfigZNSStore();


