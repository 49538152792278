import moment, { Moment } from "moment";
import dayjs from "dayjs";

class DateUtils {
    /**
     *
     * @param timeSecond
     * @param localeFormat
     */
    public formatTSDateTimeAtTZ(timeSecond: number, localeFormat: string = "en-US") {
        return new Date(timeSecond * 1000).toLocaleString(localeFormat);
    }

    /**
     *
     * @param isoDate
     * @param localeFormat
     */
    public formatISODateTimeAtTZ(isoDate: string, localeFormat: string = "vi-VN") {
        let lastChar: string = isoDate.substr(isoDate.length - 1);
        if(lastChar !== "Z") {
            isoDate += "Z"
        }
        return new Date(isoDate).toLocaleString(localeFormat);
    }

    /**
     *
     * @param isoDate isoDate UTC
     * @param localeFormat
     */
    public formatISODateAtTZ(isoDate: string, localeFormat: string = "vi-VN") {
        let lastChar: string = isoDate.substr(isoDate.length - 1);
        if(lastChar !== "Z") {
            isoDate += "Z"
        }
        return new Date(isoDate).toLocaleDateString(localeFormat);
    }

    /**
     *
     * @param isoDate isoDate UTC
     * @param localeFormat
     */
    public formatISOTimeAtTZ(isoDate: string, localeFormat: string = "vi-VN") {
        let lastChar: string = isoDate.substr(isoDate.length - 1);
        if(lastChar !== "Z") {
            isoDate += "Z"
        }
        return new Date(isoDate).toLocaleTimeString(localeFormat);
    }

    public formatDate(timeSecond: number) {
        return timeSecond ? moment(timeSecond).format('DD/MM/YYYY HH:mm A') : '';
    }


    public convertTimestamp(date?: Moment, isEndDate?: boolean): number {
        if (!date) return 0;
    
        // Clone the date to avoid mutating the original object
        const clonedDate = date.clone();
    
        if (isEndDate) {
        // Use endOf('day') to set time to 23:59:59.999 and avoid time zone issues
        return clonedDate.endOf("day").unix() * 1000;
        } else {
        // Use startOf('day') to set time to 00:00:00.000
        return clonedDate.startOf("day").unix() * 1000;
        }
    }

    public checkRangePickerTime = (minDate: dayjs.Dayjs | null, maxDate: dayjs.Dayjs | null) => {
        return {
            disabledHours: () => {
                if (maxDate && minDate && maxDate.isSame(minDate, 'day')) {
                    return Array.from({length: 24}, (_, i) => i).filter(h => h < minDate.hour() || h > maxDate.hour());
                }
                if (maxDate) {
                    return Array.from({length: 24}, (_, i) => i).filter(h => h > maxDate.hour());
                }
                if (minDate) {
                    return Array.from({length: 24}, (_, i) => i).filter(h => h < minDate.hour());
                }
                return [];
            },
            disabledMinutes: (hour: number) => {
                if (minDate && maxDate && hour === minDate.hour() && hour === maxDate.hour()) {
                    return Array.from({length: 60}, (_, i) => i).filter(m => m < minDate.minute() || m > maxDate.minute());
                }
                if (minDate && hour === minDate.hour()) {
                    return Array.from({length: 60}, (_, i) => i).filter(m => m < minDate.minute());
                }
                if (maxDate && hour === maxDate.hour()) {
                    return Array.from({length: 60}, (_, i) => i).filter(m => m > maxDate.minute());
                }
                return [];
            },
            disabledSeconds: (hour: number, minute: number) => {
                if (maxDate && minDate && hour === maxDate.hour() && minute === maxDate.minute() && hour === minDate.hour() && minute === minDate.minute()) {
                    return Array.from({length: 60}, (_, i) => i).filter(s => s < minDate.second() || s > maxDate.second());
                }
                if (minDate && hour === minDate.hour() && minute === minDate.minute()) {
                    return Array.from({length: 60}, (_, i) => i).filter(s => s < minDate.second());
                }
                if (maxDate && hour === maxDate.hour() && minute === maxDate.minute()) {
                    return Array.from({length: 60}, (_, i) => i).filter(s => s > maxDate.second());
                }
                return [];
            }
        }
    }
}

export const dateUtils = new DateUtils();