import React from "react";
import { DatePicker, DatePickerProps } from "antd";
import dayjs from "dayjs";
import { dateUtils } from "../../utils/DateUtils";
// import {dateUtils} from "../../../commons/utils/DateUtils";

interface IProps {
    value: any[],
    onChange?: any
    isError?: any,
    format?: string,
    convertDate?: string,
    minDate?: any,
    maxDate?: any,
    presets?: any,
    showTime?: string,
    placeholder?: string[],
    className?: string,
    noTextErr?: boolean,
    allowClear?: boolean,
    disabledDate?: (date: dayjs.Dayjs) => boolean,
    picker?: DatePickerProps['picker'],
    onOpenChange?: (open: boolean) => void,
    disabled?: boolean,
    isTypeMask?: boolean,
    isNeedConfirm?: boolean,
}

const DateRangePickerAnt = (item: IProps) => {
    const isValueValid = item.value?.every(val => val);

    const disabledTime = (_: dayjs.Dayjs, type: 'start' | 'end') => {
        const minDate = item?.minDate ? dayjs(item?.minDate, item.convertDate) : null;
        const maxDate = item?.maxDate ? dayjs(item?.maxDate, item.convertDate) : null;

        if (type === 'start') return dateUtils.checkRangePickerTime(minDate, maxDate)
        if (type === 'end') return dateUtils.checkRangePickerTime(minDate, maxDate)
        return {};
    };

    return (
        <>
            <DatePicker.RangePicker
                presets={item?.presets}
                className={`${item?.className} ${!isValueValid && item.isError ? 'border_error' : ''}`}
                maxDate={item?.maxDate ? dayjs(item?.maxDate, item.convertDate) : undefined}
                minDate={item?.minDate ? dayjs(item?.minDate, item.convertDate) : undefined}
                disabledTime={disabledTime}
                disabledDate={item?.disabledDate}
                disabled={item?.disabled}
                placeholder={[item?.placeholder?.[0] || "Thời gian từ", item?.placeholder?.[1] || "Thời gian đến"]}
                value={[
                    item?.value?.[0] ? dayjs(item?.value?.[0], item.convertDate) : null,
                    item?.value?.[1] ? dayjs(item?.value?.[1], item.convertDate) : null
                ]}
                showTime={item.showTime ? { format: item.showTime } : undefined}
                picker={item?.picker}
                format={{
                    format: item.format ?? 'DD/MM/YYYY',
                    type: !item?.isTypeMask ? 'mask' : undefined,
                }}
                needConfirm={item?.isNeedConfirm}
                onChange={(dates) => item.onChange(dates)}
                onOpenChange={item?.onOpenChange}
            />
            {(item.isError && !isValueValid) && !item.noTextErr && <p className="error">{item.isError}</p>}
        </>
    );
}

export default DateRangePickerAnt;