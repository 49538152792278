import React from 'react';
import {observer} from "mobx-react";
import {Spin} from "antd";

type TProps = {
    isLoading: boolean;
    action: () => void;
    title?: string;
    id?: string;
    btnConfirmName?: string;
    btnConfirmBgColor?: string;
    iconPath?: string;
}

const PopupConfirmApproveAndReject = ({
    isLoading,
    action,
    title = "Xác nhận duyệt",
    btnConfirmName = "Xác nhận",
    btnConfirmBgColor = "",
    id = "popup_approve",
    iconPath = "/assets/ico/ico_confirm.svg"
}: TProps) => {

    return (
        <div className="modal fade" id={id} role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document" style={{maxWidth: 650}}>
                <div className="modal-content border-0">
                    <div className="text-center pt-6 mb-4">
                        <img width={64} height={65} src={iconPath} alt=""/>
                        <h5 className="modal-title mt-2">{title}</h5>
                    </div>
                    <div className="modal-body pt-2">
                        <div className="action_footer d-flex justify-content-between">
                            <button id={`${id}_close_confirm`} className="bt_cancel" data-bs-dismiss="modal">Hủy</button>
                            <button 
                                className={`send-request bt_approve bg-${btnConfirmBgColor}`}
                                onClick={() => action()}
                                disabled={isLoading}
                            >
                                {isLoading ? <Spin className="text-white" /> : btnConfirmName }
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default observer(PopupConfirmApproveAndReject);