import React, {useEffect} from 'react'
import {observer} from 'mobx-react'
import Loading from '../../../common/components/Loading'
import NoContent from '../../../common/components/NoContent'
import Paginate from '../../../common/components/Paginate'
import {sttPage} from '../../../common/utils/Utils'
import { Tag } from "antd";
import ActionTable from "../../../common/components/actionTable/ActionTable";
import InputSearch from "../../../common/components/form/InputSearch";
import {dateUtils} from "../../../common/utils/DateUtils";
import {numberUtil} from "../../../common/utils/NumberUtil";
import SelectAnt from "../../../common/components/form/SelectAnt";
import { ApproveDepositRequestStatus, approveDepositRequestStatusList, approveDepositRequestStore } from './ApproveDepositRequestStore';
import { IApproveDepositRequestData } from './ApproveDepositRequestInterface';
import PopupConfirmApproveAndReject from './components/PopupConfirmApproveAndReject'

const convertApproveDepositRequestStatus = (status: string) => {
    switch (status) {
        case ApproveDepositRequestStatus.COMPLETED: return <span className="fs-14px line-height-14px px-10px py-5px rounded-100px color-158212 bg-1582121A">Hoàn thành</span>;
        case ApproveDepositRequestStatus.PENDING: return <span className="fs-14px line-height-14px px-10px py-5px rounded-100px color-674DEF bg-7B61FF1A">Đang thực hiện</span>;
        case ApproveDepositRequestStatus.FAILED:
        case ApproveDepositRequestStatus.ERROR:
            return <span className="fs-14px line-height-14px px-10px py-5px rounded-100px color-E3000F bg-E3000F0D">Thất bại</span>;
        default: return null;
    }
}

const ApproveDepositRequest = () => {

    const handleChangePage = async (data: any) => {
        approveDepositRequestStore.searchObj.page = data.selected;
        await approveDepositRequestStore.getApproveDepositRequestList();
    }

    useEffect(() => {
        approveDepositRequestStore.getApproveDepositRequestList().then();
    }, [])

    const callApi = () => {
        approveDepositRequestStore.searchObj.page = 0;
        approveDepositRequestStore.getApproveDepositRequestList().then();
    }

    return (
        <div className="enterprise_page">
            <div className='d-flex align-items-center justify-content-between mb-6'>
                <h1 className='d-flex color-005CF7 fw-bolder fs-3 align-items-center my-1'>Yêu cầu nạp tiền</h1>
            </div>
            <div className="d-flex mb-4 align-items-center">
                <InputSearch 
                    value={approveDepositRequestStore.searchObj.keyword} 
                    callApi={() => {
                        callApi()
                    }} 
                    onChange={(e: any) => approveDepositRequestStore.searchObj.keyword = e.currentTarget.value}
                />
                <SelectAnt 
                    width={220} 
                    isShowClear 
                    placeholder="Trạng thái" 
                    value={approveDepositRequestStore.searchObj.status} 
                    onChange={(e: any) => {
                        approveDepositRequestStore.searchObj.status = e
                        callApi()
                    }} 
                    options={approveDepositRequestStatusList}
                />
            </div>
            <div id='kt_ecommerce_sales_table_wrapper' className='dataTables_wrapper dt-bootstrap4 no-footer'>
                {approveDepositRequestStore.isLoading ? <Loading/> : <div className='table-responsive'>
                    {approveDepositRequestStore.approveDepositRequestList && approveDepositRequestStore.approveDepositRequestList.length > 0 ?
                        <table className='table align-middle table-hover table-rounded table-striped border gy-7 gs-7'>
                            <thead>
                            <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                <th>STT</th>
                                <th>Ngày gửi</th>
                                <th>Mã thanh toán</th>
                                <th>Người gửi</th>
                                <th>Tổng tiền</th>
                                <th className="text-center">Trạng thái</th>
                                <th>Ngày duyệt</th>
                                <th className="text-center">Thao tác</th>
                            </tr>
                            </thead>
                            <tbody>
                            {approveDepositRequestStore.approveDepositRequestList.map((item: IApproveDepositRequestData, i: number) => {
                                return (
                                    <tr key={i}>
                                        <td>{sttPage(approveDepositRequestStore.searchObj.page, i)}</td>
                                        <td>{dateUtils.formatDate(item.createdAt)}</td>
                                        <td>{item.code}</td>
                                        <td>{item?.wallet?.user?.name}</td>
                                        <td>{numberUtil.number_format(item.value)}</td>
                                        <td className="text-center">{convertApproveDepositRequestStatus(item.transactionStatus)}</td>
                                        <td>{dateUtils.formatDate(item.approvedAt)}</td>
                                        <td width='10%'>
                                            <ActionTable
                                                data={[
                                                    {
                                                        name: "Duyệt",
                                                        type: "success",
                                                        isHide: item.transactionStatus !== "PENDING",
                                                        dataTarget: 'popup_approve_deposit_request',
                                                        action: () => {
                                                            approveDepositRequestStore.changeStatusRequest = {
                                                                transactionId: item?.id,
                                                                enterpriseId: item?.enterpriseId,
                                                                userId: item?.createdUserId,
                                                            }
                                                        }
                                                    },
                                                    {
                                                        name: "Từ chối",
                                                        type: "cancel",
                                                        isHide: item.transactionStatus !== "PENDING",
                                                        dataTarget: 'popup_reject_deposit_request',
                                                        action: () => {
                                                            approveDepositRequestStore.changeStatusRequest = {
                                                                transactionId: item?.id,
                                                                enterpriseId: item?.enterpriseId,
                                                                userId: item?.createdUserId,
                                                            }
                                                        }
                                                    }
                                                ]}
                                            />
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table> : <NoContent/>}
                    <Paginate 
                        callback={(e: any) => handleChangePage(e)} 
                        totalPage={approveDepositRequestStore.totalPage}
                        currentPage={approveDepositRequestStore.searchObj.page}
                    />
                    <PopupConfirmApproveAndReject
                        id="popup_approve_deposit_request"
                        isLoading={approveDepositRequestStore.isLoadingBtn} 
                        action={() => {
                            approveDepositRequestStore.changeApproveDepositRequestStatus(ApproveDepositRequestStatus.COMPLETED);
                        }} 
                    />
                    <PopupConfirmApproveAndReject
                        id="popup_reject_deposit_request"
                        isLoading={approveDepositRequestStore.isLoadingBtn}
                        title='Từ chối duyệt'
                        iconPath="/assets/ico/ico_reject.svg"
                        btnConfirmBgColor='FF424F'
                        action={() => {
                            approveDepositRequestStore.changeApproveDepositRequestStatus(ApproveDepositRequestStatus.FAILED);
                        }} 
                    />
                </div>}
            </div>
        </div>
    )
}

export default observer(ApproveDepositRequest)