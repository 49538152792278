import {makeAutoObservable} from 'mobx'
import {enterpriseService} from './EnterpriseService'
import $ from 'jquery'
import {HttpStatusCode} from "axios";

class EnterpriseStore {
    id: any = ''
    page: any = 0
    state: any = null
    keyword: any = null
    totalPage: any = 0
    listEnterprise: any[] = []
    listState: any[] = [
        {id: 'PENDING', name: 'Chờ duyệt'},
        {id: 'APPROVED', name: 'Đã duyệt'},
        {id: 'REJECT', name: 'Từ chối'
    }]
    dataRequest: any = {
        id: '',
        name: '',
        description: '',
        parentId: null,
    }
    isOpenDetail: boolean = false
    isLoading: boolean = false
    isLoadingBt: boolean = false

    constructor() {
        makeAutoObservable(this)
    }

    async getEnterprise() {
        this.isLoading = true;
        const result = await enterpriseService.getEnterprise()
        this.isLoading = false;
        if (result.status === HttpStatusCode.Ok) {
            this.listEnterprise = result.body.data;
            this.totalPage = result.body.metadata.totalPage
        }
    }

    async getEnterpriseLoadMore() {
        this.page++;
        this.isLoading = true;
        const result = await enterpriseService.getEnterprise()
        this.isLoading = false;
        if (result.status === HttpStatusCode.Ok) {
            this.listEnterprise = [
                ...this.listEnterprise,
                ...result.body.data
            ];
            this.totalPage = result.body.metadata.totalPage
        }
    }

    async getDetail(id: any) {
        const result = await enterpriseService.getDetail(id);
        if (result.status === HttpStatusCode.Ok) {
            this.dataRequest = result.body;
        }
    }



    async changeState(id: number, state: string) {
        this.isLoadingBt = true
        const result = await enterpriseService.changeState(id, state)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.Ok) {
            toastr.success(`${state === "REJECT" ? 'Từ chối duyệt' : 'Duyệt'} doanh nghiệp thành công`)
            this.listEnterprise.map((item: any) => {
                if(item.id == id){
                    item.state = state
                }
            })
            $('.action_footer .bt_cancel').trigger('click')
        } else toastr.error(result.body.message)

    }
}

export const enterpriseStore = new EnterpriseStore();


