import {makeAutoObservable} from 'mobx'
import { IApproveDepositRequestData, ISearchObj } from './ApproveDepositRequestInterface';
import Constants from '../../../common/constants/Constants';
import { approveDepositRequestService } from './ApproveDepositRequestService';
import { HttpStatusCode } from 'axios';
import $ from "jquery";
import { profileStore } from '../../../modules/profile/ProfileStore';

export enum ApproveDepositRequestStatus {
    COMPLETED = "COMPLETED",
    FAILED = "FAILED",
    PENDING = "PENDING",
    ERROR = "ERROR",
}

export const approveDepositRequestStatusList = [
    {id: ApproveDepositRequestStatus.COMPLETED, name: "Hoàn thành"},
    {id: ApproveDepositRequestStatus.FAILED, name: "Thất bại"},
    {id: ApproveDepositRequestStatus.PENDING, name: "Đang thực hiện"}
]

const searchObjInit: ISearchObj = {
    page: 0,
    pageSize: Constants.PAGE_SIZE_DEFAULT,
    keyword: '',
    status: null
}

class ApproveDepositRequestStore {    
    isLoading: boolean = false;
    isLoadingBtn: boolean = false;

    searchObj: ISearchObj = searchObjInit;
    totalPage: number = 0;

    approveDepositRequestList: IApproveDepositRequestData[] = [];
    changeStatusRequest: {transactionId: number, enterpriseId: number, userId: number} = {
        transactionId: 0,
        enterpriseId: 0,
        userId: 0,
    }

    constructor() {
        makeAutoObservable(this)
    }

    async getApproveDepositRequestList() {
        this.isLoading = true;
        const result = await approveDepositRequestService.getApproveDepositRequest();
        this.isLoading = false;

        if (result.status === HttpStatusCode.Ok) {
            this.approveDepositRequestList = result.body.data;
            this.totalPage = result.body.metadata.totalPage;
        } else toastr.error(result.body.message)
    }

    async changeApproveDepositRequestStatus(status: string) {
        const {transactionId, enterpriseId, userId} = this.changeStatusRequest;

        const requestData = {
            status: status,
            transactionId: transactionId,
            userId: userId,
            enterpriseId: enterpriseId,
        }

        this.isLoadingBtn = true;
        const result = await approveDepositRequestService.changeApproveDepositRequestStatus(requestData);
        this.isLoadingBtn = false;

        if (result.status === HttpStatusCode.Ok) {
            $("#popup_approve_deposit_request_close_confirm").trigger("click");
            $("#popup_reject_deposit_request_close_confirm").trigger("click");
            toastr.success(`${status === ApproveDepositRequestStatus.COMPLETED ? "Duyệt" : "Từ chối"} yêu cầu nạp tiền thành công.`);
            this.approveDepositRequestList = this.approveDepositRequestList.map(item => {
                return item.id === transactionId
                    ? {
                        ...item,
                        transactionStatus: status,
                        approvedAt: Date.now(),
                    }
                    : item;
            });
        } else toastr.error(result.body.message)
    }
}

export const approveDepositRequestStore = new ApproveDepositRequestStore();


