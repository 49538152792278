import {getRequest, IApiResponse} from '../../../common/helpers/RequestHelper'
import { reportCompareZNSStore } from './ReportCompareZNSStore';

class ReportCompareZNSService {
    public getZnsReconciliations(): Promise<IApiResponse> {
        const {page, fromDate, toDate, enterpriseIds, zaloOAIds} = reportCompareZNSStore.searchObj;

        return getRequest(`/iss_365_zalo/zns_reconciliations?page=${page}`
            + `${enterpriseIds ? `&enterpriseId=${enterpriseIds}` : ''}`
            + `${zaloOAIds ? `&oaId=${zaloOAIds}` : ''}`
            + `${fromDate ? `&fromTime=${fromDate}` : ''}`
            + `${toDate ? `&toTime=${toDate}` : ''}`
        );
    }

    public getZnsReconciliationStatistical(): Promise<IApiResponse> {
        const {page, fromDate, toDate, enterpriseIds, zaloOAIds} = reportCompareZNSStore.searchObj;
        
        return getRequest(`/iss_365_zalo/zns_reconciliations/statistical`
            + `${enterpriseIds ? `&enterpriseId=${enterpriseIds}` : ''}`
            + `${zaloOAIds ? `&oaId=${zaloOAIds}` : ''}`
            + `${fromDate ? `&fromTime=${fromDate}` : ''}`
            + `${toDate ? `&toTime=${toDate}` : ''}`
        );
    }
}

export const reportCompareZNSService = new ReportCompareZNSService()

